<template>
    <div class="row card" id="ManageListCourse">
      <div class="card-body col-md-12">
        <div class="mx-auto d-flex justify-content-end">
            <el-button @click="dialogAttendance = true" class="text-white rounded" style="background-color: rgba(27, 197, 189, 1);">Điểm danh</el-button>
        </div>
        <div class="col-md-12">
            <div class="panel-container show">
                <div class="panel-content">
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <label class="mb-0 mt-2" for=""><b class="fs-2">Học viên</b></label>
                      <el-input type="text" size="medium" v-model="query.keyword" class="mt-1"
                                placeholder="Nhập mã, tên, số điện thoại" autocomplete="false" clearable></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                        <label class="mb-0 mt-2" for=""><b class="fs-2">Chương trình đào tạo</b></label>
                        <el-select v-model="query.program" class="w-100 mt-1" placeholder="Chọn chương trình đào tạo">
                          <el-option
                              v-for="item in programs"
                              :key="item.quantumleap_id"
                              :label="item.name"
                              :value="item.quantumleap_id">
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                      </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                      <label class="mb-0 mt-2" for=""><b class="fs-2">Văn phòng làm việc</b></label>
                      <el-select clearable v-model="query.branch" filterable class="w-100 mt-1"
                                 placeholder="Chọn văn phòng làm việc">
                        <el-option
                            v-for="item in listBranch"
                            :key="item.id"
                            :label="item.name_alias ? item.name_alias : item.name"
                            placeholder="Chọn văn phòng làm việc"
                            :value="item.id">
                          <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                        </el-option>
                      </el-select>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                        <label class="mb-0 mt-2" for=""><b class="fs-2 mt-1">Trạng thái học</b></label>
                        <el-select v-model="query.status" collapse-tags clearable class="w-100 mt-1" placeholder="Chọn trạng thái học" multiple>
                          <el-option
                              v-for="item in statusStudyDefine"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                            <span style="float: left">{{ item.value }}</span>
                          </el-option>
                        </el-select>
                    </el-col>

                    <!-- <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                        <label class="mb-0 mt-2" for=""><b class="fs-2 mt-1">Thời gian tạo</b></label>
                        <date-picker class="w-100 mt-1 imput-time"  align="right" placeholder="Thời gian tạo" range v-model="query.date_range" format="DD-MM-YYYY"
                        valueType="YYYY-MM-DD"></date-picker>
                    </el-col> -->

                    <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                      <label class="mb-0 mt-2" for=""><b class="fs-2"></b></label>
                      <button :disabled="loading_search"
                              class="btn btn-primary w-100 mt-5"
                              @click="search">
                        <i v-bind:class="[loading_search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                      </button>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <table class="table table-vertical-center  table-bordered mt-4" v-loading="loading_search" element-loading-text="Loading...">
                      <thead>
                        <tr style="background-color: #F8F8F8;">
                          <th>Mã học viên</th>
                          <th>Tên học viên</th>
                          <th>Số điện thoại</th>
                          <th>Email</th>
                          <th>Văn phòng</th>
                          <th>Trạng thái học viên</th>
                          <th v-for="(course, index) in courses" :key="index">
                            Trạng thái {{ course?.title }}
                          </th>
                          <th>Thời gian hoàn thành đào tạo</th>
                        </tr>
                      </thead>

                      <tbody v-if="listSaleLevelUpTraining?.length > 0">
                        <tr v-for="(item, index) in listSaleLevelUpTraining">
                          <td>{{ item?.id ? item?.id + 100000 : '' }}</td>
                          <td>{{ item?.name ? item?.name : '' }}</td>
                          <td>{{ item?.phone ? item?.phone : '' }}</td>
                          <td>{{ item?.email ? item?.email : '' }}</td>
                          <td>{{ item?.branch_name ? item?.branch_name : '' }}</td>
                          <td>
                            <div :class="statusStudentDefineCss[item.status]" class="rounded-pill w-60 text-white text-center">
                                <span>{{ statusStudentDefine[item.status] }}</span>
                            </div>
                          </td>
                          <td v-for="(course, index) in item?.program_detail?.courses" :key="index">
                            <div class="status-schedule">
                                <div class="d-inline-flex mr-2 rounded-circle" style="width: 10px; height: 10px;" :class="typeCouserDefineCss[course?.status]"></div>
                                <span>{{ typeCouserDefine[course?.status] }}</span>
                            </div>
                          </td>
                          <td>
                            <div v-if="item.program_detail.completedDate" class="deadline">
                              <div class="rounded"
                                style="width: 100%; font-size: 13px; padding: 3px 2px 2px 2px; color: #000;">
                                {{ item.completedDate }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="9" class="event-title no-data text-center">Không có bản ghi</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b
                      style="color: rgb(54, 153, 255);">{{
                      totalData ? totalData : 0 }}</b>
                    </div>
                </div>

                  <div id="paginate" class="edu-paginate mx-auto d-flex justify-content-center">
                    <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                        :next-class="'page-link'" :page-class="'page-item'">
                    </paginate>
                </div>
              </div>
            </div>

            <el-dialog title="Điểm danh học đào tạo" :visible.sync="dialogAttendance" width="75%">
              <div>
                <div class="col-12 d-flex mb-3">
                  <div class="col-6 p-0">
                    <div>
                      <label for="">Khóa học <span class="text-danger">*</span></label>
                    </div>
                    <el-select v-model="query_attend.course" @change="changeCourseAttend(); getCandidataAttendance()" >
                      <el-option v-for="item in course_attendes" :key="item.id" :label="item.title" placeholder="Chọn cơ sở"
                        :value="item.id">
                        <span>{{ item.title }}</span>
                      </el-option>
                    </el-select><br>
                  </div>
                  <div class="col-6 p-0">
                    <div><label for="">Chọn văn phòng</label></div>
                    <el-select v-model="query_attend.branch" filterable @change="changeCourseAttend(); getCandidataAttendance()">
                      <el-option v-for="item in listBranch" :key="item.id" :label="item.name_alias ? item.name_alias : item.name" placeholder="Chọn cơ sở"
                        :value="item.id">
                        <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                      </el-option>
                    </el-select> <br>
                    <span class="text-danger"></span>

                  </div>
                </div>
                <div class="col-12">
                  <div class="example-preview table-responsive overflow-auto mt-3">
                    <table class="table text-left table-hover table-bordered table-vertical-center b-table">
                      <thead>
                        <th scope="col">STT</th>
                        <th scope="col">Ứng viên</th>
                        <th scope="col">Văn phòng</th>
                        <th scope="col">Điểm danh</th>
                      </thead>
                      <tbody v-loading="loading_attendance">
                        <tr v-for="(item, index) in candidateAttendances" :key="index">
                          <td>{{ ++index ?? "" }}
                          </td>
                          <td>{{ item ? 100000 + item?.id + ' - ' + item?.name : 'Không có dữ liệu' }}</td>
                          <td>{{ item?.branch_name ?? 'Không có dữ liệu' }}</td>
                              <td><input @change="checkAttendance(checkboxAttendance)" :disabled="item.disable_attend" type="checkbox" :value="item.id" v-model="checkboxAttendance"></td>
                        </tr>
                      </tbody>
                    </table>
                      </div>
                  <div class="d-flex justify-content-center">
                    <paginate v-model="page_attend" :page-count="last_page_attend" :page-range="3" :margin-pages="1" :click-handler="clickCallbackAttend"
                      :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
                      :pageLinkClass="'page-link'" :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
                      :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
                    </paginate>
                  </div>
                  </div>
                  <div class="d-flex dialog-footer justify-content-end mt-3">
                    <el-button size="small" type="submit" :disabled="disableAttendance || loading_attendance"
                     @click="attendance" style="background-color: #8c46f6e0;" class="text-white rounded">
                     <i v-bind:class="[loading_attendance ? 'el-icon-loading' : '']"></i> Điểm danh
                    </el-button>
                  </div>
              </div>
            </el-dialog>
        </div>
    </div>
  </template>

  <script>
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import {mapGetters, mapState} from "vuex";
  import moment from "moment-timezone"
  import { GET_BRANCHES_BY_AREAS } from "../../../core/services/store/center/branch.module";
  import { SALE_LEVEL_UP_COURSES, SALE_LEVEL_UP_PROGRAMS, ATTEND_SALE, SALE_LEVEL_UP_TRAINING } from "../../../core/services/store/course/course.module";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: "ManageListCourse",
    components: {
      DatePicker
  },
    data() {
      return {
        page: 1,
        last_page: 1,
        page_attend: 1,
        last_page_attend: 1,
        totalData: '',
        course: '',
        filterbranch: '',
        listBranch: {},
        paging: {
          current_page: 1,
          per_page: 10,
          total: 0,
          link: {},
          total_pages: '',

        },
        area: {
        id: '',
        name: '',
        type_id: ''
      },
      query: {
          keyword: '',
          program: '',
          area: '',
          status: '',
          branch: ''
        },
      query_attend: {
        course: '',
        area: '',
        branch: ''
      },
        loading_table: false,
        loading_search: false,
        dialogAttendance: false,
        loading_attendance: false,
        disableAttendance : true,
        checkboxAttendance: [],
        listSaleLevelUpTraining:[],
        candidateAttendances: [],
        course_attendes: [],
        programs : [],
        courses: [],

        statusStudyDefine: [
            {id: 0, value: 'Chưa đào tạo'},
            {id: 1, value: 'Đang đào tạo'},
            {id: 3, value: 'Hoàn thành đào tạo'},
        ],

        statusStudentDefine: {
          0: "Chưa đào tạo",
          1: "Đang đào tạo",
          2: "Bị loại",
          3: "Hoàn thành đào tạo",
        },

        statusStudentDefineCss: {
          0: "bg-secondary",
          1: "warning",
          2: "primary",
          3: "successs",
        },

        typeCouserDefine: {
          0: "Chưa học",
          1: "Đang học",
          2: "Đã học",
          3: "Hoàn thành",
        },

        typeCouserDefineCss: {
          0: "circle-secondary",
          1: "warning",
          2: "primary",
          3: "successs",
        },
      }
    },

    mounted() {
      this.getAllPrograms();
      this.getBranchesByArea(this?.currentUser?.profile?.khuvuc_id);
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Chương trình", icon: 'far fa-sitemap'},
        {title: "Báo cáo đào tạo", icon: 'far fa-chart-bar'}
      ]);
      this.getAllCourses();
    },

    methods: {
      search() {
        if(this.query.program) {
          this.page = 1;
          this.changeProgram();
          this.getUserByProgramOptional()
        }
      },

      getBranchesByArea(area) {
        this.$store.dispatch(GET_BRANCHES_BY_AREAS, {area}).then((response) => {
          this.listBranch = response.data;
        })
      },

    getUserByProgramOptional() {
      this.loading_table = true;
      this.loading_search = true;
      this.$store.dispatch(SALE_LEVEL_UP_TRAINING, {
          page: this.page,
          keyword: this.query.keyword,
          area:this?.currentUser?.profile?.khuvuc_id,
          status: this.query.status,
          branch: this.query.branch,
          route_id: this.query.route_id,
          program : this.query.program
        }).then((res) => {
          if(res.data) {
            this.listSaleLevelUpTraining = res.data;
            this.totalData = res.pagination.total;
            this.last_page = res.pagination.last_page;
          }
        }).catch(() => {

        }).finally(() => {
          this.loading_table = false
          this.loading_search = false
      });
    },

     getAllCourses() {
      this.$store.dispatch(SALE_LEVEL_UP_COURSES).then((data) => {
        this.course_attendes = data.data;
        if (data.error) {
          this.$message({
            type: 'error',
            message: 'lấy hoá học thất bại!',
            showClose: true
          })
        }
      })
    },

    async getAllPrograms() {
     await this.$store.dispatch(SALE_LEVEL_UP_PROGRAMS).then((data) => {
        if (!data.error) {
          this.programs = data.data;
        }
      })
    },

    attendance() {
      this.$store.dispatch(ATTEND_SALE, {
        course_id: this.query_attend.course,
        user_ids: this.checkboxAttendance
      })
      .then((res) => {
        if (!res.error) {
          this.is_attendance = false;
          this.$message({
            type: 'success',
            message: 'Điểm danh thành công!',
            showClose: true
          });
          this.query_attend.course = '';
          this.candidateAttendances = '';
          this.getUserByProgramOptional();
        } else {
          this.$message({
            type: 'error',
            message: 'Điểm danh thất bại!',
            showClose: true
          })
        }
        this.dialogAttendance = false;
      }).catch(error => {
        this.$message({
          type: 'error',
          message: error,
          showClose: true
        })
      })
    },

    changeProgram() {
      this.courses = this.programs.find(program => program.quantumleap_id == this.query.program).courses;
      this.query.route_id = this.programs.find(program => program.quantumleap_id == this.query.program).id;
    },

    getCandidataAttendance() {
      if(!this.query_attend.course) {
        return
      }
      this.loading_attendance= true;
      this.checkboxAttendance = []
      this.loadingAttend = true,
        this.$store.dispatch(SALE_LEVEL_UP_TRAINING, {
          page: this.page_attend,
          area: this?.currentUser?.profile?.khuvuc_id,
          branch: this.query_attend.branch,
          course_id: this.query_attend.course,
          route_id: this.query_attend.route_id,
          program: this.query_attend.program
        }).then((res) => {
          this.candidateAttendances = res?.data;
          this.totalDataAttend = res?.pagination?.total;
          this.last_page_attend = res?.pagination?.last_page;
          this.loading_attendance = false;
        }).catch(() => {
          this.loading_attendance = false
        });
    },

    changeCourseAttend() {
      this.programs.forEach(program => {
        program.courses.forEach(course => {
          if (this.query_attend.course == course.id) {
            this.query_attend.route_id = program.id;
            this.query_attend.program = program.quantumleap_id;
          }
        })
      })
    },
    getCurentMoth() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
      this.query.date_range = [startOfMonth,endOfMonth]
    },

    checkAttendance(item){
      let lengthCheckBox = item.length;
      this.disableAttendance = true;
      if(lengthCheckBox > 0) {
        this.disableAttendance = false;
      }else{
        this.disableAttendance = true;
      }
    },

    clickCallbackAttend(obj) {
      this.page_attend = obj
      this.getCandidataAttendance();
    },

    clickCallback(obj) {
      this.page = obj
      this.getUserByProgramOptional();
    },

    },
    computed: {
      ...mapState({
        errors: state => state.auth.errors
      }),
      ...mapGetters(["currentUser","getCountWaitUser"]),
    },
  }
  </script>

  <style>
    #ManageListCourse .primary {
      background-color: rgba(0, 102, 255, 1);
    }
    #ManageListCourse .warning{
      background-color: rgba(255, 153, 0, 1);
    }

    #ManageListCourse .successs{
      background-color: rgba(0, 128, 13, 1);
    }

    #ManageListCourse .circle-secondary{
      background-color: rgba(214, 213, 213, 1);
    }

    #ManageListCourse .imput-time .mx-input {
      height: 38px !important;
    }
  </style>
